import React, { useContext } from 'react'
import { Logger } from '../logger'

export const ARCLumiLogic = (lumiSliderRef, state, dispatch) => {
  const l = Logger(false, '[ARCLumiLogic]')
  return {
    async onLumiStart (lumiRange) {},
    async onLumiSet (lumiRange) {},
    async onLumiUpdate (lumiRange) {},
    async onLumiEnd (lumiRange) {},
    async onAccuracyUpdate (value) {},
    async onBoostUpdate (value) {},
    async onApply () {
    },
    async onCancel () {
    },
    async onPicker () {
      lumiSliderRef.current.startSlider()
    },
    async onSliderClick () {
      lumiSliderRef.current.startSlider()
      lumiSliderRef.current.startSingleHoverValue()
    }
  }
}

const ARCLumiLogicContext = React.createContext({})
export const useARCLumiLogic = () => {
  return useContext(ARCLumiLogicContext)
}

export const ARCLumiLogicProvider = ({ lumiSliderRef, children }) => {
  return (
    <ARCLumiLogicContext.Provider
      value={{
        logic: ARCLumiLogic(lumiSliderRef)
      }}
    >
      <>{children}</>
    </ARCLumiLogicContext.Provider>
  )
}
