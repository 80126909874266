import React, { useEffect, useState } from 'react'
import { HintButton } from '../../common/index'
import Slider from '../../common/slider/normalSlider'
import { format, FormattedMessage } from '../../common/style/formattedMessage'
import * as Style from '../../common/style/panelStyle.js'
import { useGlobalState } from '../../../common/uxp/state.js'
import { Icons } from '../icons/index.js'
import '../nouislider.css'
import LumiSlider from './arcMask_lumiSlider.js'
import { useARCLumiLogic } from '../../../common/uxp/shared/lumiMask_logic'

export const LumiMask = ({ lumiSliderRef }) => {
  const tooltip = format({ id: `arcmask.lumirange.hint` })
  const { logic } = useARCLumiLogic()
  const { state, dispatch } = useGlobalState()

  const [feather, setFeather] = useState(0)
  const [boost, setBoost] = useState(50)

  useEffect(() => {
    async function update () {
      if (!state.lumi.started) return
      logic && await logic.onAccuracyUpdate(feather)
    }
    update()
  }, [feather])

  useEffect(() => {
    async function update () {
      if (!state.lumi.started) return
      logic && await logic.onBoostUpdate(boost)
    }
    update()
  }, [boost])

  useEffect(() => {
    if (!state.lumi.started) {
      lumiSliderRef.current.stopSlider()
    }
  }, [state.lumi.started])

  return (
    <>
      <Style.Row>
        <Style.Col b={2}>
          <HintButton
            disabled={state.lumi.started}
            id='arcmask.lumirange'
            onClick={async () => {
              logic && await logic.onPicker()
            }}
          >
            <Style.FilledIcon slot='icon'>
              <Icons.Eyedropper />
            </Style.FilledIcon>
          </HintButton>
        </Style.Col>
        <Style.Col>
          <LumiSlider
            ref={lumiSliderRef}
            onClick={async () => {
              logic && await logic.onSliderClick()
            }}
          >
            {state.settings.tooltips && !state.lumi.started && (
              <Style.Tooltip isTop={false}>{tooltip}</Style.Tooltip>
            )}
          </LumiSlider>
        </Style.Col>
      </Style.Row>
      {state.lumi.started && (
        <Style.Overlay style={{ zIndex: state.lumi.active ? '10' : '9' }}>
          <Style.Absolute>
            <Style.Row>
              <Style.Col>
                <Style.Header>
                  <FormattedMessage id='arcmask.lumirange.settings' />
                </Style.Header>
              </Style.Col>
            </Style.Row>
            <Style.Row>
              <Style.Col>
                <Style.ExtraSliderEntry>
                  <Style.ExtraSliderRow>
                    <Style.ExtraSliderTitle>
                      <FormattedMessage id='arcmask.feather' />
                    </Style.ExtraSliderTitle>
                    <Style.ExtraSliderTitle>
                      {Math.abs(feather)}
                    </Style.ExtraSliderTitle>
                  </Style.ExtraSliderRow>
                  <Style.ExtraSlider>
                    <Slider
                      min={-40}
                      max={0}
                      start={0}
                      step={1}
                      onUpdate={values => {
                        setFeather(values[0])
                      }}
                    />
                  </Style.ExtraSlider>
                </Style.ExtraSliderEntry>
              </Style.Col>
            </Style.Row>
            <Style.Row>
              <Style.Col>
                <Style.ExtraSliderEntry>
                  <Style.ExtraSliderRow>
                    <Style.ExtraSliderTitle>
                      <FormattedMessage id='arcmask.boost' />
                    </Style.ExtraSliderTitle>
                    <Style.ExtraSliderTitle>{boost}</Style.ExtraSliderTitle>
                  </Style.ExtraSliderRow>
                  <Style.ExtraSlider>
                    <Slider
                      min={1}
                      max={100}
                      start={50}
                      step={1}
                      onUpdate={values => {
                        setBoost(values[0])
                      }}
                    />
                  </Style.ExtraSlider>
                </Style.ExtraSliderEntry>
              </Style.Col>
            </Style.Row>
            <Style.Row>
              <Style.Col b={2}></Style.Col>
              <Style.Col b={4}>
                <HintButton
                  id='arcmask.cancel'
                  onClick={async () => {
                    logic && await logic.onCancel()
                  }}
                />
              </Style.Col>
              <Style.Col></Style.Col>
              <Style.Col b={4}>
                <HintButton
                  id='arcmask.buildmask'
                  onClick={async () => {
                    logic && await logic.onApply()
                  }}
                />
              </Style.Col>
              <Style.Col b={2}></Style.Col>
            </Style.Row>
          </Style.Absolute>
        </Style.Overlay>
      )}
    </>
  )
}
