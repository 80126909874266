import React, { useContext } from 'react'
import { Logger, GlobalLogging } from '../logger'

const ARCRetouchLogicContext = React.createContext({})

export const ARCRetouchLogic = () => {
  const l = Logger(GlobalLogging, '[ARCRetouchLogic]')
  return {
    async onInit () {},
    async onClear () {},
    async onSolarCurve () {},
    async onFrequencySeparation_Gaussian () {},
    async onFrequencySeparation_Surface () {},
    async onFrequencySeparation_Median () {},
    async onOrton_Heavy () {},
    async onOrton_Soft () {},
    async onDodgeBurn_Grey () {},
    async onDodgeBurn_Curves () {},
    async onDodgeBurn_Auto () {},
    async onNewFrequency_start (blurType) {},
    async onNewFrequency_update (radius, texturePreviewMode) {},
    async onNewFrequency_cancel () {},
    async onNewFrequency_apply () {}
  }
}

export const useARCRetouchLogic = () => {
  return useContext(ARCRetouchLogicContext)
}

export const ARCRetouchLogicProvider = ({ children }) => {
  return (
    <ARCRetouchLogicContext.Provider
      value={{
        logic: ARCRetouchLogic()
      }}
    >
      <>{children}</>
    </ARCRetouchLogicContext.Provider>
  )
}
