import React from 'react'
import { HintButton } from '../common/index'
import { FormattedMessage } from '../common/style/formattedMessage'
import * as Style from '../common/style/panelStyle.js'
import { useARCRetouchLogic } from '../../common/uxp/shared/arcretouch_logic'
import { useGlobalState } from '../../common/uxp/state'

export default () => {
  const { logic } = useARCRetouchLogic()
  const { state } = useGlobalState()

  return (
    <>
      <Style.Row>
        <Style.Col>
          <Style.Header>
            <FormattedMessage id='arcretouch.dodgeburn_title' />
          </Style.Header>
        </Style.Col>
      </Style.Row>
      <Style.Row>
        <Style.Col b={6}>
          <HintButton
            disabled={state.frequency.started}
            isTop={true}
            id='arcretouch.dodgeburn_grey'
            onClick={async () => {
              logic && await logic.onDodgeBurn_Grey()
            }}
          />
        </Style.Col>
        <Style.Col>
          <HintButton
            disabled={state.frequency.started}
            isTop={true}
            id='arcretouch.dodgeburn_curves'
            onClick={async () => {
              logic && await logic.onDodgeBurn_Curves()
            }}
          />
        </Style.Col>
        <Style.Col b={6}>
          <HintButton
            disabled={state.frequency.started}
            isTop={true}
            id='arcretouch.dodgeburn_auto'
            onClick={async () => {
              logic && await logic.onDodgeBurn_Auto()
            }}
          />
        </Style.Col>
      </Style.Row>
    </>
  )
}
