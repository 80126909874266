import styled from 'styled-components'

export const CalculatorWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const CalculatorButton = styled.div`
  width: 18px;
  height: 18px;
  margin: 0px 2px;
`
