import React from 'react'
import * as Style from '../common/style/panelStyle.js'
import { ARCLumiLogicProvider } from '../../common/uxp/shared/lumiMask_logic.js'

import { MaskButton } from './arcMask_core.js'

import { LumiMask } from './luminosity/arcMask_lumi'

export default () => {
  const lumiSliderRef = React.useRef(null)

  return (
    <>
      <ARCLumiLogicProvider lumiSliderRef={lumiSliderRef}>
        <LumiMask lumiSliderRef={lumiSliderRef} />
      </ARCLumiLogicProvider>
      {/* // -- Style.Row 1--> */}
      <Style.Row>
        <Style.Col b={10}>
          <MaskButton mask='Darks-1' id='arcmask.darks_1' />
        </Style.Col>
        <Style.Col b={10}>
          <MaskButton mask='Lights-1' id='arcmask.lights_1' />
        </Style.Col>
      </Style.Row>
      {/* // -- Style.Row 2--> */}
      <Style.Row>
        <Style.Col>
          <MaskButton mask='Darks-2' id='arcmask.darks_2' />
        </Style.Col>
        <Style.Col b={4}>
          <MaskButton mask='Mids-2' id='arcmask.mids_2' />
        </Style.Col>
        <Style.Col>
          <MaskButton mask='Lights-2' id='arcmask.lights_2' />
        </Style.Col>
      </Style.Row>
      {/* // -- Style.Row 3--> */}
      <Style.Row>
        <Style.Col>
          <MaskButton mask='Darks-3' id='arcmask.darks_3' />
        </Style.Col>
        <Style.Col b={8}>
          <MaskButton mask='Mids-3' id='arcmask.mids_3' />
        </Style.Col>
        <Style.Col>
          <MaskButton mask='Lights-3' id='arcmask.lights_3' />
        </Style.Col>
      </Style.Row>
      {/* // -- Style.Row 4--> */}
      <Style.Row>
        <Style.Col>
          <MaskButton mask='Darks-4' id='arcmask.darks_4' />
        </Style.Col>
        <Style.Col b={12}>
          <MaskButton mask='Mids-4' id='arcmask.mids_4' />
        </Style.Col>
        <Style.Col>
          <MaskButton mask='Lights-4' id='arcmask.lights_4' />
        </Style.Col>
      </Style.Row>
      {/* // -- Style.Row 5--> */}
      <Style.Row>
        <Style.Col>
          <MaskButton mask='Darks-5' id='arcmask.darks_5' />
        </Style.Col>
        <Style.Col b={16}>
          <MaskButton mask='Mids-5' id='arcmask.mids_5' />
        </Style.Col>
        <Style.Col>
          <MaskButton mask='Lights-5' id='arcmask.lights_5' />
        </Style.Col>
      </Style.Row>
      {/* // -- Style.Row 6--> */}
      <Style.Row>
        <Style.Col>
          <MaskButton mask='Darks-6' id='arcmask.darks_6' />
        </Style.Col>
        <Style.Col b={18}>
          <MaskButton mask='Mids-6' id='arcmask.mids_6' />
        </Style.Col>
        <Style.Col>
          <MaskButton mask='Lights-6' id='arcmask.lights_6' />
        </Style.Col>
      </Style.Row>
      {/* // -- end of Style.Row --> */}
    </>
  )
}
