import React from "react"
import styled, { css } from "styled-components"
import { Button } from "./panelStyle"

export const Dialog = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
  background-color: #313131dd;
  width: 100%;
  height: 100%;
  z-index: 999;

  a {
    color: #fe9b00;
    &:hover {
      color: #e3a20f;
    }
  }
`

export const DialogContent = styled.div`
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 290px;
  min-height: 80px;
  border: solid red 1px;
  color: #dbdbdb;
  background-color: #313131;
`

export const Close = styled.a`
  position: absolute;
  right: 8px;
  top: 8px;
  width: 16px;
  height: 16px;
  z-index: 999;
  color: #dbdbdbaa;

  &:hover {
    color: #dbdbdb;
  }
`

export const Message = styled.div`
  padding: 16px;
  padding-top: 24px;
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: white;
`

export const Headline = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0px;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
`

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const GoldButton = styled(Button)`
  color: black;
  margin: 8px 0px;
  background: #fe9b00;
  width: 100%;
  height: 36px;
  padding: 16px;
  font-weight: bold;
  font-size: 11px;

  &:hover {
    cursor: pointer;
    background-color: #e3a20f;
  }
`