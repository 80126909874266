import React, { useContext } from 'react'

export const ARCCalculatorLogic = (state, dispatch, ps) => {
  return {
    async onPlus () {},
    async onMinus () {},
    async onCross () {},
    async onEquals () {}
  }
}

const ARCCalculatorLogicContext = React.createContext({})
export const useARCCalculatorLogic = () => {
  return useContext(ARCCalculatorLogicContext)
}

export const ARCCalculatorLogicProvider = ({ children }) => {
  return (
    <ARCCalculatorLogicContext.Provider
      value={{
        logic: ARCCalculatorLogic()
      }}
    >
      <>{children}</>
    </ARCCalculatorLogicContext.Provider>
  )
}
