import React from 'react'
import { HintButton } from '../../common/index'
import * as Style from '../../common/style/panelStyle.js'
import { Icons } from '../icons/index.js'

import { useGlobalState } from '../../../common/uxp/state.js'
import '../nouislider.css'
import { CalculatorButton, CalculatorWrapperRow } from './style.js'
import { useARCCalculatorLogic } from '../../../common/uxp/shared/calculator_logic'

export const MaskCalculator = () => {
  const { logic } = useARCCalculatorLogic()
  const { state, dispatch } = useGlobalState()

  const hasMaskOrOperand = state.hasMask || state.calculator.operandSaved

  return (
    <CalculatorWrapperRow>
      <CalculatorButton>
        <HintButton
          disabled={!hasMaskOrOperand}
          selected={state.calculator.operator === 'plus'}
          id='arcmask.calculator.plus'
          isTop={true}
          onClick={async () => {
            logic && await logic.onPlus()
          }}
        >
          <Style.FilledIcon slot='icon'>
            <Icons.Plus alt='plus' />
          </Style.FilledIcon>
        </HintButton>
      </CalculatorButton>

      <CalculatorButton>
        <HintButton
          disabled={!hasMaskOrOperand}
          selected={state.calculator.operator === 'minus'}
          id='arcmask.calculator.minus'
          isTop={true}
          onClick={async () => {
            logic && await logic.onMinus()
          }}
        >
          <Style.FilledIcon slot='icon'>
            <Icons.Minus alt='minus' />
          </Style.FilledIcon>
        </HintButton>
      </CalculatorButton>

      <CalculatorButton>
        <HintButton
          disabled={!hasMaskOrOperand}
          selected={state.calculator.operator === 'cross'}
          id='arcmask.calculator.cross'
          isTop={true}
          onClick={async () => {
            logic && await logic.onCross()
          }}
        >
          <Style.FilledIcon slot='icon'>
            <Icons.CrossProduct alt='Cross Product' />
          </Style.FilledIcon>
        </HintButton>
      </CalculatorButton>

      <CalculatorButton>
        <HintButton
          disabled={!state.calculator.operandSaved}
          id='arcmask.calculator.equals'
          isTop={true}
          onClick={async () => {
            logic && await logic.onEquals()
          }}
        >
          <Style.FilledIcon slot='icon'>
            <Icons.Equals alt='equals' />
          </Style.FilledIcon>
        </HintButton>
      </CalculatorButton>
    </CalculatorWrapperRow>
  )
}
