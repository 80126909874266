export const PaywallType = {}
PaywallType.Preview = 'Preview'
PaywallType.QuickApply = 'Rapid'
PaywallType.WhiteFolder = 'WhiteFolder'
PaywallType.DarkFolder = 'DarkFolder'
PaywallType.InfiniteColor = 'InfiniteColor'
PaywallType.LumiRange = 'LumiRange'
PaywallType.TrueLuminosity = 'TrueLuminosity'
PaywallType.Calculator = 'Calculator'
PaywallType.ColorMasks = 'ColorMasks'
PaywallType.ComingSoon = 'ComingSoon'
PaywallType.Paintmask = 'Paintmask'

export const MenuPopupType = {}
MenuPopupType.Activated = 'Activated'
MenuPopupType.Deactivated = 'Deactivated'

export const OrtonType = () => {}
OrtonType.Heavy = 'Orthon_Heavy'
OrtonType.Soft = 'Orthon_Soft'

export const FrequencySeparationType = () => {}
FrequencySeparationType.GAUSSIAN = 'Frequency_Separation_Gaussian'
FrequencySeparationType.MEDIAN = 'Frequency_Separation_Median'
FrequencySeparationType.SURFACE = 'Frequency_Separation_Surface'

export const TexturePreviewMode = () => {}
TexturePreviewMode.NORMAL = 'texturePreviewMode_Normal'
TexturePreviewMode.WHITE = 'texturePreviewMode_White'
TexturePreviewMode.BLACK = 'texturePreviewMode_Black'
