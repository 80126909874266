import Nouislider from 'nouislider-react'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { Logger } from '../../../common/uxp/logger'
import { useARCLumiLogic } from '../../../common/uxp/shared/lumiMask_logic'
import './lumiSliderStyle.css'
import * as Style from './style'

const RANGE_STEP = 100
const UPDATE_DELAY = 30

const makeRange = (sliderValue, accuracy) => {
  const start = sliderValue
  const end = 4096
  return {
    start: start,
    end: end,
    accuracy: Math.pow(accuracy, 2)
  }
}

//value between 0 and 100
const makeRangeFromLuminosity = (value, accuracy) => {
  const shiftedValue = Math.round((value * 4096) / 100)
  const start = Math.max(0, shiftedValue - RANGE_STEP)
  const end = Math.min(4096, shiftedValue + RANGE_STEP)
  return {
    start: start,
    end: end,
    accuracy: Math.pow(accuracy, 2)
  }
}

const getLumiRange = (values, accuracy) => {
  const start = parseInt(values[0])
  const end = parseInt(values[1])
  var range = {
    start: start,
    end: end,
    accuracy: Math.pow(accuracy, 2)
  }
  return range
}

const colorClasses = ['c-1-color', 'c-2-color']
const handleClasses = ['h-1-color', 'h-2-color']

const LumiSlider = React.forwardRef(({ onClick, children }, ref) => {
  const l = Logger(false, '[ARCMask_LumiSlider]')

  const { logic } = useARCLumiLogic()
  const [sliderRef, setSliderRef] = useState(null)

  var dragged = false
  var updateTimer
  const [started, setStarted] = useState(false)
  let [hoverValue, setHoverValue] = useState(0)
  const [accuracy, setAccuracy] = useState(0)

  //slider event handlers

  const onUpdate = useCallback(
    values => {
      // l.log("onUpdate", values)

      let lumiRange = getLumiRange(values, accuracy)
      if (dragged) {
        if (updateTimer) {
          clearTimeout(updateTimer)
        }
        updateTimer = setTimeout(async () => {
          logic && await logic.onLumiUpdate(lumiRange)
        }, UPDATE_DELAY)
      }
    },
    [sliderRef, logic, accuracy]
  )

  const onSet = useCallback(
    async values => {
      // l.log("onSet", values)

      let lumiRange = getLumiRange(values, accuracy)

      logic && await logic.onLumiSet(lumiRange)
    },
    [sliderRef, logic, accuracy]
  )

  //on drag start of slider
  const onStart = useCallback(
    async values => {
      // l.log("onStart", values)
      dragged = true
      sliderRef.noUiSlider.on('update', onUpdate)
      let lumiRange = getLumiRange(values, accuracy)
      logic && await logic.onLumiStart(lumiRange)
    },
    [sliderRef, logic, accuracy]
  )

  //on drag end of slider
  const onEnd = useCallback(
    async values => {
      l.log('onEnd', values)
      dragged = false
      sliderRef.noUiSlider.off('update')
      let lumiRange = getLumiRange(values, accuracy)
      logic && await logic.onLumiEnd(lumiRange)
    },
    [sliderRef, logic, accuracy]
  )

  const onHover = useCallback(
    value => {
      if (started) return
      setHoverValue(value)
    },
    [sliderRef, started]
  )

  useEffect(() => {
    if (sliderRef && onEnd) {
      l.log('rebind onEnd')
      sliderRef.noUiSlider.off('end')
      sliderRef.noUiSlider.on('end', onEnd)
    }
  }, [sliderRef, onEnd])

  useEffect(() => {
    if (sliderRef && onStart) {
      l.log('rebind onStart')
      sliderRef.noUiSlider.off('start')
      sliderRef.noUiSlider.on('start', onStart)
    }
  }, [sliderRef, onStart])

  useEffect(() => {
    if (sliderRef && onSet) {
      l.log('rebind onSet')
      sliderRef.noUiSlider.off('set')
      sliderRef.noUiSlider.on('set', onSet)
    }
  }, [sliderRef, onSet])

  useEffect(() => {
    if (sliderRef && onUpdate) {
      l.log('rebind onUpdate')
      sliderRef.noUiSlider.off('update')
      sliderRef.noUiSlider.on('update', onUpdate)
    }
  }, [sliderRef, onUpdate])

  useEffect(() => {
    if (sliderRef) {
      if (!started) sliderRef.noUiSlider.on('hover', onHover)
      else {
        //refresh for accuracy
        sliderRef.noUiSlider.set(sliderRef.noUiSlider.get())
      }
    }
    return () => {
      l.log('useEffect destroy', sliderRef)
      if (sliderRef) sliderRef.noUiSlider.off('hover')
    }
  }, [sliderRef, logic, accuracy])

  useImperativeHandle(ref, () => ({
    //public methods

    updateAccuracy: async newAccuracy => {
      l.log('updateAccuracy', newAccuracy)
      await setAccuracy(newAccuracy)
    },

    startSingleHoverValue: async () => {
      l.log('startSingleHoverValue', hoverValue)
      await ref.current.startSingleSlider(hoverValue)
    },

    startSingleSlider: async singleValue => {
      l.log('startSingleSlider', singleValue)
      await ref.current.startLumiSlider(
        makeRange(Math.round(singleValue), accuracy)
      )
    },

    //value between 0 and 100
    startSingleLuminosity: async value => {
      l.log('startSingleLuminosity', value)
      await ref.current.startLumiSlider(
        makeRangeFromLuminosity(Math.round(value), accuracy)
      )
    },

    startLumiSlider: async lumiRange => {
      l.log('startLumiSlider', lumiRange)
      sliderRef.noUiSlider.set([lumiRange.start, lumiRange.end])
      logic && await logic.onLumiStart(lumiRange)
    },

    startSlider: () => {
      if (started) return
      l.log('startSlider', sliderRef)
      sliderRef.querySelectorAll('.noUi-handle').forEach(function (e) {
        e.removeAttribute('disabled')
      })
      sliderRef.noUiSlider.on('start', onStart)
      sliderRef.noUiSlider.on('end', onEnd)
      sliderRef.noUiSlider.on('set', onSet)
      setStarted(true)
    },

    stopSlider: () => {
      if (!started) return
      l.log('stopSlider', sliderRef)
      sliderRef.querySelectorAll('.noUi-handle').forEach(function (e) {
        e.setAttribute('disabled', true)
      })
      sliderRef.noUiSlider.off('start')
      sliderRef.noUiSlider.off('end')
      sliderRef.noUiSlider.off('set')
      sliderRef.noUiSlider.off('update')
      sliderRef.noUiSlider.off('hover')
      setStarted(false)
    }
  }))

  return (
    <Style.LumiSliderWrapper
      className='lumi-slider-wrapper'
      onClick={() => {
        if (started) return
        onClick()
      }}
    >
      <Nouislider
        className='lumi-slider'
        instanceRef={instance => {
          if (instance && !sliderRef) {
            const handles = instance.querySelectorAll('.noUi-handle')
            const connects = instance.querySelectorAll('.noUi-connect')

            connects.forEach((connect, i) => {
              connect.classList.add(colorClasses[i])
            })
            handles.forEach((handle, i) => {
              handle.classList.add(handleClasses[i])
              handle.setAttribute('disabled', true)
            })

            setSliderRef(instance)
          }
        }}
        range={{ min: 0, max: 4096 }}
        start={[0, 4096]}
        behaviour='hover-unconstrained-tap'
        connect={[true, false, true]}
        animate={false}
      />
      {children}
    </Style.LumiSliderWrapper>
  )
})

export default LumiSlider
