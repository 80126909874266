import React from "react"
import styled, { css } from "styled-components"
import * as CommonStyle from "../../common/style/index"
import { Tooltip } from "../../common/style/panelStyle"


export const LumiSliderWrapper = styled.div`
  margin-top: 1px;
  padding: 0 2px;
  &:hover ${Tooltip} {
    display: block;
  }
`