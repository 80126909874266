import React from 'react'
import { HintButton } from '../common/index'
import { FormattedMessage } from '../common/style/formattedMessage'
import * as Style from '../common/style/panelStyle.js'
import { useARCRetouchLogic } from '../../common/uxp/shared/arcretouch_logic'
import { useGlobalState } from '../../common/uxp/state'

export default () => {
  const { logic } = useARCRetouchLogic()
  const { state } = useGlobalState()

  return (
    <>
      <Style.Row>
        <Style.Col>
          <Style.Header>
            <FormattedMessage id='arcretouch.tools_title' />
          </Style.Header>
        </Style.Col>
      </Style.Row>
      <Style.Row>
        <Style.Col b={10}>
          <HintButton
            disabled={state.frequency.started}
            id='arcretouch.solarcurve'
            onClick={async () => {
              logic && await logic.onSolarCurve()
            }}
          />
        </Style.Col>
        <Style.Col></Style.Col>
      </Style.Row>
      <Style.Separator />
    </>
  )
}
